 import './App.css';
import Landingpage from './landingPage';

function App() {
  return (
    <div className="App">
    <Landingpage/>
    </div>
  );
}

export default App;
